@import '../abstracts/variables';
@import '../abstracts/functions';
@import '../abstracts/keyframes';

.section {
}

.wrapper {
    &__card-team {
        padding: 1rem;
        background-color: white;
        box-shadow: 0px 4px 46px 0px #131b2d26;

        .image {
            overflow: hidden;
            > img {
                width: 100%;
                height: 400px;
                border-radius: 4px;
                object-fit: cover;

                @media screen and(max-width: 1024px) {
                    height: 300px;
                }
            }
            .sosmed {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -100%;
                z-index: 2;
                transition: 0.5s;

                a {
                    img {
                        object-fit: contain;
                    }
                }
            }
            .bg {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 100%;
                background: #00000094;
                transition: 0.5s;
            }
        }

        &:hover {
            .image {
                .sosmed {
                    bottom: 2rem;
                }
                .bg {
                    top: 0;
                }
            }
        }

        .desc {
            padding-top: 1rem;
        }
    }
    &__list-auto {
        @media screen and(max-width: $br-md) {
            overflow: auto;

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: transparent;
            }

            .list__category {
                width: 890px;
            }
        }
    }
    &__number-work {
        .items {
            @media screen and(max-width: $br-mm) {
                width: 100%;
                margin-bottom: 2rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &__sosmed-left {
        position: absolute;
        left: 6rem;
        @media screen and(max-width: $br-md) {
            left: -1rem;
        }

        a {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 1px solid #e4e4e7;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__list-document {
        width: 100%;
        max-width: 350px;
        padding: 1rem 1.5rem;
        background-color: #f3f3f3;
    }
    &__list-detail-project {
        display: flex;
        align-items: flex-start;
        margin: 0 -4rem;

        .items {
            margin: 0 4rem;
        }

        @media screen and(max-width: 1024px) {
            margin: 0 -2.5rem;

            .items {
                margin: 0 2.5rem;
            }
        }

        @media screen and(max-width: $br-md) {
            margin: 0 -1.3rem;

            .items {
                margin: 0 1.3rem;
            }
        }

        @media screen and(max-width: $br-sm) {
            flex-wrap: wrap;

            .items {
                width: 100%;
            }
        }
    }
    &__card-image-project {
        width: 100%;
        height: 300px;
        overflow: hidden;

        &:hover {
            .cover {
                top: 0;
            }
            .desc {
                bottom: 1rem;
            }
        }

        .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            object-fit: cover;
        }

        .cover {
            transition: 0.5s;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 100%;
            background: rgba(0, 0, 0, 0.6);
        }

        .desc {
            transition: 0.5s;
            position: absolute;
            bottom: -100%;
            left: 50%;
            transform: translateX(-50%);

            a {
                text-decoration: underline !important;

                &:hover {
                    text-decoration: underline !important;
                }
            }
            .category {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 -5px;

                .items {
                    padding: 4px 8px;
                    border: 1px solid #ffffff8f;
                    border-radius: 50px;
                    margin: 0 5px;
                }
            }
        }
    }
    &__left-line {
        position: relative;

        &.blog {
            background-color: #e4e4e7;
            padding: 1rem;
            &::before {
                background-color: #222222;
            }
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 6px;
            background-color: color('orange');
        }
    }
    &__brand-testimonial {
        img {
            filter: grayscale(100%);
            @media screen and(max-width: $br-md) {
                margin: 0 1rem;
                margin-bottom: 1rem;
            }
            @media screen and(max-width: $br-xx) {
                width: 120px;
                margin: 0 1.5rem;
                margin-bottom: 1.5rem;
            }
            @media screen and(max-width: $br-xxs) {
                width: 120px;
                margin: 0 1rem;
                margin-bottom: 1rem;
            }
        }
    }
    &__card-testimonial {
        padding: 1.5rem 2rem;
        background-color: white;
        box-shadow: 0px 4px 46px rgba(19, 27, 45, 0.15);

        div {
            .img {
                width: 96px;
                height: 96px;
                border-radius: 4px;
                object-fit: cover;
            }
        }
    }
    &__list-footer {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__field {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        box-sizing: none;
        outline-style: none;
        box-shadow: none;
        height: 64px;

        &.textarea {
            height: initial;
            padding: 1rem;
        }

        &:hover {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            box-sizing: none;
            outline-style: none;
            box-shadow: none;
        }
        &:focus {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            box-sizing: none;
            outline-style: none;
            box-shadow: none;
        }
        &:active {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            box-sizing: none;
            outline-style: none;
            box-shadow: none;
        }
    }
    &__form-contact {
        padding: 2rem;
        box-shadow: 0px 4px 46px 0px #131b2d26;
    }
    &__bg-orange-wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(50% 50% at 50% 50%, rgba(234, 107, 0, 0.67) 0%, #ff5e14 100%);
    }
    &__card-blog {
        background-color: white;
        .img {
            width: 100%;
            height: 316px;
            object-fit: cover;
        }

        .desc {
            padding: 1.5rem 0;

            div {
                a {
                    text-decoration: underline;
                }
            }
        }
    }
    &__slider-construction {
        margin: 0 -15px;
        .items {
            padding: 0 15px;
        }

        .slick-dots {
            li {
                &.slick-active {
                    button {
                        border: 1px solid color('orange');
                    }
                }
                button {
                    border-radius: 50%;
                    &::before {
                        color: color('orange') !important;
                        opacity: 1;
                    }
                }
            }
        }
    }
    &__card-service {
        background-color: white;
        box-shadow: 0px 4px 46px 0px #131b2d26;

        .img {
            width: 100%;
            height: 316px;
            object-fit: cover;
        }

        .desc {
            padding: 1.5rem;

            div {
                a {
                    text-decoration: underline;
                }
            }
        }
    }
    &__bg-head {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.44);

        &.two {
            background: #222222de;
        }
    }
}

.list {
    &__normal {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__category {
        display: flex;
        align-items: center;
        justify-content: center;

        .items {
            margin: 0 10px;
            padding: 8px 16px;
            border: 1px solid #d9d9d9;

            &.active {
                border: 1px solid color('orange');
                color: color('orange');
            }
        }
    }
    &__detail-info {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 10px;
        }
    }
    &__menu-side {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 10px;

            a {
                color: color('black');
                transition: 0.5s;
                &.active {
                    color: color('orange');
                }

                &:hover {
                    color: color('orange');
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.clamp {
    &__md-3 {
        @media screen and(max-width: $br-md) {
            display: -webkit-box !important;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}

.text {
}

.images {
    &__blog-head {
        width: 100%;
        height: 475px;
        object-fit: cover;

        @media screen and(max-width: $br-md) {
            height: 350px;
        }
        @media screen and(max-width: $br-sm) {
            height: 300px;
        }
    }
    &__cover-detail {
        width: 100%;
        height: 290px;
        object-fit: cover;

        @media screen and(max-width: 1024px) {
            height: 230px;
        }
        @media screen and(max-width: $br-md) {
            height: 200px;
        }
        @media screen and(max-width: $br-sm) {
            height: 150px;
        }
        @media screen and(max-width: $br-xx) {
            height: 100px;
        }
    }
    &__cover-head {
        width: 100%;
        height: 490px;
        object-fit: cover;

        @media screen and(max-width: 1024px) {
            height: 350px;
        }

        @media screen and(max-width: $br-md) {
            height: 300px;
        }
        @media screen and(max-width: $br-xx) {
            height: 250px;
        }
    }
    &__dt-service {
        width: 100%;
        height: 440px;
        object-fit: cover;

        @media screen and(max-width: $br-md) {
            height: 350px;
        }
    }
    &__wrap-contact {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__construction {
        width: 490px;
        height: 390px;
        object-fit: cover;
        @media screen and(max-width: $br-mm) {
            height: 300px;
            width: 100%;
        }
    }
    &__wrap-head {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: 0;
        top: 0;
    }
}

.btn {
    &__mm-w-60 {
        @media screen and(max-width: $br-mm) {
            width: 60%;
        }
    }
    &__blog-more {
        div {
            svg {
                path {
                    transition: 0.5s;
                }
            }
        }

        &:hover {
            div {
                svg {
                    path {
                        stroke: white;
                    }
                }
            }
        }
    }
}

.path {
}

.list {
}

.line {
}

.pt {
    &__100 {
        padding-top: 100px;
        @media screen and(max-width: $br-md) {
            padding-top: 61px;
        }
    }
}

.p {
    &__4 {
        padding: 4rem;
    }
}

.px {
    &-xx-10 {
        @media screen and(max-width: $br-xx) {
            padding: 0 10px;
        }
    }
}

.mt {
    &__min {
        &-17 {
            margin-top: -17rem;

            @media screen and(max-width: $br-xs) {
                margin-top: -14rem;
            }
            @media screen and(max-width: $br-xxs) {
                margin-top: -13rem;
            }
        }
    }
    &__10 {
        margin-top: 10rem;
    }
    &__100 {
        margin-top: 100px;
    }
    &__5 {
        margin-top: 5rem;

        &.md-0 {
            @media screen and(max-width: $br-md) {
                margin-top: 0rem;
            }
        }
    }
    &__10 {
        margin-top: 10rem;

        &.lg-0 {
            @media screen and(max-width: $br-lg) {
                margin-top: 0rem;
            }
        }
    }
    &__18 {
        margin-top: 18rem;

        &.md-0 {
            @media screen and(max-width: $br-md) {
                margin-top: 0rem;
            }
        }
    }
}

.hr {
    &__footer {
        border-top: 1px solid #322f37;
    }
    &__line-contact {
        border-top: 1px solid #d9d9d9;
    }
}

.mb {
    &__min {
        &-14 {
            margin-bottom: -14rem;
        }
    }
    &__4 {
        margin-bottom: 4rem;
    }
    &__5 {
        margin-bottom: 5rem;
    }

    &__md-2 {
        @media screen and(max-width: $br-md) {
            margin-bottom: 1.5rem;
        }
    }
}

// typography

.text {
    &__163 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 100px !important;
            }
        }

        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 90px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 65px !important;
            }
        }

        &-xs {
            @media screen and(max-width: $br-xs) {
                font-size: 50px !important;
            }
        }
    }

    &__100 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 80px !important;
            }
        }

        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 70px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 60px !important;
            }
        }

        &-mm {
            @media screen and(max-width: $br-mm) {
                font-size: 50px !important;
            }
        }

        &-xx {
            @media screen and(max-width: $br-xx) {
                font-size: 45px !important;
            }
        }

        &-xxs {
            @media screen and(max-width: $br-xxs) {
                font-size: 40px !important;
            }
        }
    }
    &__150 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 120px !important;
            }
        }

        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 100px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 60px !important;
            }
        }

        &-mm {
            @media screen and(max-width: $br-mm) {
                font-size: 50px !important;
            }
        }

        &-xx {
            @media screen and(max-width: $br-xx) {
                font-size: 45px !important;
            }
        }

        &-xxs {
            @media screen and(max-width: $br-xxs) {
                font-size: 40px !important;
            }
        }
    }

    &__200 {
        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 150px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 125px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-mm) {
                font-size: 100px !important;
            }
        }

        &-xx {
            @media screen and(max-width: $br-xx) {
                font-size: 85px !important;
            }
        }

        &-xxs {
            @media screen and(max-width: $br-xxs) {
                font-size: 75px !important;
            }
        }
    }

    &__77 {
        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 50px !important;
            }
        }
    }

    &__80 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 55px !important;
            }
        }

        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 65px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 50px !important;
            }
        }

        &-xs {
            @media screen and(max-width: $br-xs) {
                font-size: 40px !important;
            }
        }
    }

    &__70 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 60px !important;
            }
        }

        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 50px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 40px !important;
            }
        }

        &-xs {
            @media screen and(max-width: $br-xs) {
                font-size: 40px !important;
            }
        }

        &-xxs {
            @media screen and(max-width: $br-xxs) {
                font-size: 35px !important;
            }
        }
    }

    &__65 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 45px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 35px !important;
            }
        }
        &-xxs {
            @media screen and(max-width: $br-xxs) {
                font-size: 28px !important;
            }
        }
    }

    &__60 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 50px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 35px !important;
            }
        }

        &-xxs {
            @media screen and(max-width: $br-xxs) {
                font-size: 30px !important;
            }
        }
    }

    &__50 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 40px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 35px !important;
            }
        }
        &-xs {
            @media screen and(max-width: $br-xs) {
                font-size: 25px !important;
            }
        }
    }

    &__25 {
        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 20px !important;
            }
        }
    }

    &__45 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 30px !important;
            }
        }

        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 24px !important;
            }
        }

        &-mm {
            @media screen and(max-width: $br-mm) {
                font-size: 16px !important;
            }
        }
    }

    &__40 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 30px !important;
            }
        }

        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 28px !important;
            }
        }

        &-mm {
            @media screen and(max-width: $br-mm) {
                font-size: 24px !important;
            }
        }
    }

    &__30 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 25px !important;
            }
        }

        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 25px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 25px !important;
            }
        }

        &-xx {
            @media screen and(max-width: $br-xx) {
                font-size: 20px !important;
            }
        }
    }

    &__32 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 24px !important;
            }
        }

        &-xs {
            @media screen and(max-width: $br-xs) {
                font-size: 18px !important;
            }
        }
        &-xxs {
            @media screen and(max-width: $br-xxs) {
                font-size: 16px !important;
            }
        }
    }

    &__36 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 28px !important;
            }
        }
    }

    &__28 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 20px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 20px !important;
            }
        }
    }

    &__35 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 28px !important;
            }
        }

        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 20px !important;
            }
        }

        &-xs {
            @media screen and(max-width: $br-xs) {
                font-size: 18px !important;
            }
        }
    }

    &__38 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 30px !important;
            }
        }
    }

    &__90 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 70px !important;
            }
        }

        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 55px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 40px !important;
            }
        }

        &-xs {
            @media screen and(max-width: $br-xs) {
                font-size: 35px !important;
            }
        }

        &-xxs {
            @media screen and(max-width: $br-xs) {
                font-size: 30px !important;
            }
        }
    }

    &__33 {
        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 25px !important;
            }
        }

        &-xx {
            @media screen and(max-width: $br-xx) {
                font-size: 18px !important;
            }
        }
    }

    &__18 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 16px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 16px !important;
            }
        }

        &-xs {
            @media screen and(max-width: $br-xs) {
                font-size: 14px !important;
            }
        }

        &-xxs {
            @media screen and(max-width: $br-xxs) {
                font-size: 12px !important;
            }
        }
    }

    &__16 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 14px !important;
            }

            &-12 {
                @media screen and(max-width: 1024px) {
                    font-size: 12px !important;
                }
            }

            &-10 {
                @media screen and(max-width: 1024px) {
                    font-size: 10px !important;
                }
            }
        }

        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 12px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 12px !important;
            }
        }

        &-mm {
            @media screen and(max-width: $br-mm) {
                font-size: 10px !important;
            }

            &-12 {
                @media screen and(max-width: $br-mm) {
                    font-size: 12px !important;
                }
            }
        }

        &-xx {
            @media screen and(max-width: $br-xx) {
                font-size: 10px !important;
            }
        }
    }

    &__20 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 18px !important;
            }
        }
        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 14px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 12px !important;
            }
        }
    }

    &__120 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 90px !important;
            }
        }

        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 69px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 75px !important;
            }
        }

        &-xxs {
            @media screen and(max-width: $br-xxs) {
                font-size: 55px !important;
            }
        }
    }

    &__26 {
        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 20px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 14px !important;
            }
        }
    }

    &__22 {
        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 16px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 14px !important;
            }
        }
    }

    &__12 {
        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 10px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 10px !important;
            }

            &-8 {
                @media screen and(max-width: $br-sm) {
                    font-size: 8px !important;
                }
            }
        }

        &-xs {
            @media screen and(max-width: $br-xs) {
                font-size: 8px !important;
            }

            &-10 {
                @media screen and(max-width: $br-xs) {
                    font-size: 10px !important;
                }
            }
        }

        &-mm {
            @media screen and(max-width: $br-mm) {
                font-size: 8px !important;
            }

            &-10 {
                @media screen and(max-width: $br-mm) {
                    font-size: 10px !important;
                }
            }
        }
    }

    &__14 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 12px !important;
            }

            &-10 {
                @media screen and(max-width: 1024px) {
                    font-size: 10px !important;
                }
            }
        }

        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 12px !important;
            }
        }

        &-sm {
            &-8 {
                @media screen and(max-width: $br-sm) {
                    font-size: 8px !important;
                }
            }
        }

        &-mm {
            @media screen and(max-width: $br-mm) {
                font-size: 9px !important;
            }

            &-10 {
                @media screen and(max-width: $br-mm) {
                    font-size: 10px !important;
                }
            }
        }

        &-xx {
            @media screen and(max-width: $br-xx) {
                font-size: 10px !important;
            }

            &-8 {
                @media screen and(max-width: $br-xx) {
                    font-size: 8px !important;
                }
            }
        }
    }

    &__27 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 18px !important;
            }
        }
    }

    &__24 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 18px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 16px !important;
            }
        }
        &-xs {
            @media screen and(max-width: $br-xs) {
                font-size: 14px !important;
            }
        }
    }

    &__25 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 20px !important;
            }
        }
    }

    &__36 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 25px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 20px !important;
            }
        }
    }

    &__58 {
        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 45px !important;
            }
        }
    }

    &__48 {
        &-1024 {
            @media screen and(max-width: 1024px) {
                font-size: 40px !important;
            }
        }

        &-md {
            @media screen and(max-width: $br-md) {
                font-size: 35px !important;
            }
        }

        &-sm {
            @media screen and(max-width: $br-sm) {
                font-size: 30px !important;
            }
        }
        &-xx {
            @media screen and(max-width: $br-xx) {
                font-size: 24px !important;
            }
        }

        &-xxs {
            @media screen and(max-width: $br-xxs) {
                font-size: 18px !important;
            }
        }
    }
}

.w {
    &__mm-100 {
        @media screen and(max-width: $br-mm) {
            width: 100%;
        }
    }
}

.wrap {
    &__flex {
        &-1024 {
            &-100 {
                @media screen and(max-width: 1024px) {
                    flex-flow: wrap;
                }
                > .grid {
                    @media screen and(max-width: 1024px) {
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                    }
                }
            }

            &-50 {
                @media screen and(max-width: 1024px) {
                    flex-flow: wrap;
                }
                > .grid {
                    @media screen and(max-width: 1024px) {
                        flex: 0 0 45%;
                        max-width: 45%;
                    }
                }
            }

            &-90 {
                &-3 {
                    @media screen and(max-width: 1024px) {
                        flex-flow: wrap;
                    }
                    > .grid {
                        @media screen and(max-width: 1024px) {
                            flex: 0 0 calc(90% / 3);
                            max-width: calc(90% / 3);
                        }
                    }
                }
            }

            &-auto {
                @media screen and(max-width: 1024px) {
                    flex-flow: wrap;
                }
                > .grid {
                    @media screen and(max-width: 1024px) {
                        flex: 0 0 auto !important;
                        max-width: inherit !important;
                    }
                }
            }
        }

        &-md {
            &-100 {
                @media screen and(max-width: $br-md) {
                    flex-flow: wrap;
                }
                > .grid {
                    @media screen and(max-width: $br-md) {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }

            &-50 {
                @media screen and(max-width: $br-md) {
                    flex-flow: wrap;
                }
                > .grid {
                    @media screen and(max-width: $br-md) {
                        flex: 0 0 45%;
                        max-width: 45%;
                    }
                }
            }

            &-auto {
                @media screen and(max-width: $br-md) {
                    flex-flow: wrap;
                }
                > .grid {
                    @media screen and(max-width: $br-md) {
                        flex: 0 0 auto !important;
                        max-width: inherit !important;
                    }
                }
            }
        }

        &-sm {
            &-100 {
                @media screen and(max-width: $br-sm) {
                    flex-flow: wrap;
                }
                > .grid {
                    @media screen and(max-width: $br-sm) {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }

            &-50 {
                @media screen and(max-width: $br-sm) {
                    flex-flow: wrap;
                }
                > .grid {
                    @media screen and(max-width: $br-sm) {
                        flex: 0 0 45%;
                        max-width: 45%;
                    }
                }
            }
        }

        &-xs {
            &-100 {
                @media screen and(max-width: $br-xs) {
                    flex-flow: wrap;
                }
                > .grid {
                    @media screen and(max-width: $br-xs) {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }

            &-50 {
                @media screen and(max-width: $br-sm) {
                    flex-flow: wrap;
                }
                > .grid {
                    @media screen and(max-width: $br-sm) {
                        flex: 0 0 45%;
                        max-width: 45%;
                    }
                }
            }
        }

        &-mm {
            &-100 {
                @media screen and(max-width: $br-mm) {
                    flex-flow: wrap;
                }
                > .grid {
                    @media screen and(max-width: $br-mm) {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }

            &-50 {
                @media screen and(max-width: $br-sm) {
                    flex-flow: wrap;
                }
                > .grid {
                    @media screen and(max-width: $br-sm) {
                        flex: 0 0 45%;
                        max-width: 45%;
                    }
                }
            }

            &-4 {
                @media screen and(max-width: $br-mm) {
                    flex-flow: wrap;
                }
                > .grid {
                    @media screen and(max-width: $br-mm) {
                        flex: 0 0 calc(100% / 6);
                        max-width: calc(100% / 6);
                    }
                }
            }
        }
    }
}

// animated
@keyframes move1 {
    0% {
        transform: translate(0);
    }
    25% {
        transform: translate(13.5rem, 14.5rem);
    }
    75% {
        transform: translate(27rem, 0rem);
    }
    100% {
        transform: translate(0);
    }
}
@keyframes move12 {
    0% {
        transform: translate(0);
    }
    25% {
        transform: translate(9.8rem, 13rem);
    }
    75% {
        transform: translate(19.7rem, 0rem);
    }
    100% {
        transform: translate(0);
    }
}
@keyframes move2 {
    0% {
        transform: translate(0);
    }
    25% {
        transform: translate(-27rem, 0rem);
    }
    75% {
        transform: translate(-13.4rem, 14.5rem);
    }
    100% {
        transform: translate(0);
    }
}
@keyframes move22 {
    0% {
        transform: translate(0);
    }
    25% {
        transform: translate(-19.5rem, 0rem);
    }
    75% {
        transform: translate(-10rem, 13rem);
    }
    100% {
        transform: translate(0);
    }
}
@keyframes move3 {
    0% {
        transform: translate(0);
    }
    25% {
        transform: translate(13.5rem, -14.5rem);
    }
    75% {
        transform: translate(-13.5rem, -14.5rem);
    }
    100% {
        transform: translate(0);
    }
}
@keyframes move32 {
    0% {
        transform: translate(0);
    }
    25% {
        transform: translate(10rem, -13rem);
    }
    75% {
        transform: translate(-10rem, -13rem);
    }
    100% {
        transform: translate(0);
    }
}
